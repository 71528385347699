import React from 'react'
import Header from './components/header/Header'
// import Navigation from './components/navigation/Navigation'
import Markov from './components/markov/Markov'
// import Experience from './components/experience/Experience'
// import Services from './components/services/Services'
// import Portfolio from './components/portfolio/Portfolio'
// import Testimonials from './components/testimonials/Testimonials'
// import Contact from './components/contact/Contact'
// import Footer from './components/footer/Footer'
import Snowfall from 'react-snowfall'

const App = () => {
  return (
    <>
      <Snowfall
        style={{
          position: 'fixed',
          height: '200vh',
        }}
        snowflakeCount={200}
        speed={[1.0, 3.0]}
        wind={[-0.5, 0.5]}
        radius={[0.5, 3.0]}
      />
      <Header />
      {/* <Navigation /> */}
      {/* <Snowfall /> */}
      <Markov />
      {/* <Experience /> */}
      {/* <Portfolio /> */}
      {/* <Testimonials /> */}
      {/* <Contact /> */}
      {/* <Footer /> */}
    </>
  )
}

export default App